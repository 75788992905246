





















































































































import {defineComponent, ref, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import VueApexCharts from 'vue-apexcharts';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import {inject} from '@/container';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import {useUOttawaTask5SlopeIntercept} from '@/tasks/composables/useUOttawaTask5SlopeIntercept';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaTask5',
  components: {
    AiLoadingOverlay,
    CalculationInput,
    STextarea,
    StembleLatex,
    AssistedGradingWritten,
    ApexChart: VueApexCharts,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const {inputs, colDefs, equation, apexSeries, apexOptions, addRun, removeRun} = inject(
      useUOttawaTask5SlopeIntercept,
      {
        pressure: 'Pressure (kPa)',
        volume: 'Volume (mL)',
        dependent: 'Dependent',
        independent: 'Independent',
      },
      'independent',
      'dependent',
      'dependentIndependent',
      props.isMarking,
      'P',
      '\\left(\\frac{1}{V}\\right)',
      20,
      325,
      0,
      0.15
    );
    inputs.value.uOttawaTask5Procedure = null;
    inputs.value.uOttawaTask5 = null;
    return {
      pressureVolumeColDefs: colDefs,
      boylesLaw: equation,
      inputs,
      apexOptions,
      apexSeries,
      addRun,
      removeRun,
    };
  },
  computed: {
    showPagination(): boolean {
      return !!this.inputs.dependentIndependent && this.inputs.dependentIndependent.length > 10;
    },
  },
});
