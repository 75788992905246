var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"mb-4"},[_c('h2',[_vm._v("Boyle's Law Discussion")])]),_c('v-row',[_c('p',{staticClass:"mb-6"},[_c('b',[_vm._v("Please Note")]),_vm._v(": If you are writing the report with your partner, from this point onwards, all material you enter on Stemble must be identical to that entered by your partner, with the exception of your raw data. ")])]),_c('v-row',[_c('p',{staticClass:"mb-3"},[_vm._v(" a) In the table below, enter your experimental data for pressure and volume measurements as well as the values for whatever you choose to be your dependent and independent variables. Be sure to enter at least six (6) data points to improve the quality of your results. ")])]),_c('v-row',{staticClass:"mb-2"},[(!_vm.isMarking)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.addRun}},[_vm._v("Add Run")]):_vm._e()],1),_c('v-row',{staticClass:"mb-3"},[_c('v-data-table',{staticClass:"mb-6",attrs:{"headers":_vm.pressureVolumeColDefs,"items":_vm.inputs.dependentIndependent,"hide-default-footer":!_vm.showPagination},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},(!_vm.isMarking)?{key:"item.pressure",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","hide-details":true,"dense":"","single-line":""},on:{"input":function($event){item.pressure = $event !== '' ? Number($event) : null}},model:{value:(item.pressure),callback:function ($$v) {_vm.$set(item, "pressure", _vm._n($$v))},expression:"item.pressure"}})]}}:null,(!_vm.isMarking)?{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","hide-details":true,"dense":"","single-line":""},on:{"input":function($event){item.volume = $event !== '' ? Number($event) : null}},model:{value:(item.volume),callback:function ($$v) {_vm.$set(item, "volume", _vm._n($$v))},expression:"item.volume"}})]}}:null,(!_vm.isMarking)?{key:"item.dependent",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","hide-details":true,"dense":"","single-line":""},on:{"input":function($event){item.dependent = $event !== '' ? Number($event) : null}},model:{value:(item.dependent),callback:function ($$v) {_vm.$set(item, "dependent", _vm._n($$v))},expression:"item.dependent"}})]}}:null,(!_vm.isMarking)?{key:"item.independent",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","hide-details":true,"dense":"","single-line":""},on:{"input":function($event){item.independent = $event !== '' ? Number($event) : null}},model:{value:(item.independent),callback:function ($$v) {_vm.$set(item, "independent", _vm._n($$v))},expression:"item.independent"}})]}}:null,(!_vm.isMarking)?{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('v-icon',{on:{"click":function($event){return _vm.removeRun(index)}}},[_vm._v(_vm._s(_vm.$i('common.close')))])]}}:null],null,true)})],1),_c('v-row',{staticClass:"mb-8"},[_c('apex-chart',{staticClass:"flex-grow-1",attrs:{"options":_vm.apexOptions,"series":_vm.apexSeries,"height":"400"}})],1),_c('v-row',[_c('p',{staticClass:"mb-2"},[_vm._v(" b) Enter the final procedure determined by you and your partner in the lab and approved by your TA for the Verification of Boyle’s Law. ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('s-textarea',{attrs:{"outlined":""},model:{value:(_vm.inputs.uOttawaTask5Procedure),callback:function ($$v) {_vm.$set(_vm.inputs, "uOttawaTask5Procedure", $$v)},expression:"inputs.uOttawaTask5Procedure"}})],1)],1),_c('v-row',[_c('p',{staticClass:"mb-2"},[_vm._v(" c) Provide your discussion regarding Boyle's Law in the space provided below. ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('s-textarea',{attrs:{"outlined":""},model:{value:(_vm.inputs.uOttawaTask5),callback:function ($$v) {_vm.$set(_vm.inputs, "uOttawaTask5", $$v)},expression:"inputs.uOttawaTask5"}})],1)],1),(_vm.isSubmitting)?_c('ai-loading-overlay'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }